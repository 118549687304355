/*------------------------------------*\
    $HEADER
\*------------------------------------*/

$header-height: 84px;

body {
    @include mq($nav-break-min) {
        //padding-top: $header-height;
    }
}

.siteHeader {
    height: $header-height;
    z-index: 1000;
    @include mq($nav-break-max, max) {
        position: relative;
    }
}


.navToggle {
    text-transform: none;
    position: absolute;
    right: 0;
    top: 22px;
    color: #3a3a3a;
    margin: 0;
    padding: 0 18px;

    &:hover,
    &:active,
    &:focus {
        color: $colorCru-gold;
        text-decoration: none;
    }

    @include mq($nav-break-min) {
        display: none;
    }
}

.navToggle-item {
    display: inline-block;
    vertical-align: middle;
    line-height: $header-height - $gold-bar-size;
}

.navToggle--close {
    color: $colorCru-gray;

    &:hover,
    &:active,
    &:focus {
        color: $colorCru-gold;
    }
}

#sticky {
    width: 100%;
    z-index: 1;
   }
