.steps-wrap {
  .steps-single {
    width: 33.3333%;
    float: left;
    text-align: center;

    @include respond-to(extrasmall) {
      display: block;
      width: auto;
      float: none;
      text-align: left;
    }

    .step-bar {
      position: relative;

      @include respond-to(extrasmall) {
        float: left;
      }

      &::after {
        position: absolute;
        top: 50%;
        display: block;
        width: 100%;
        height: 3px;
        background: $colorCru-border;
        content: " ";

        @include respond-to(extrasmall) {
          display: none;
        }
      }
    }

    .step-digit {
      position: relative;
      z-index: 2;
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50px;
      background: $colorCru-border;
      font-family: $data-font;
      font-size: 14px;
      font-weight: bold;
      line-height: 32px;
      text-align: center;

      @include respond-to(extrasmall) {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    &.on {
      .step-bar {
        &::after {
          background: $colorCru-gold;
          color: $colorCru-white;
        }        
      }

      .step-digit {
        background: $colorCru-gold;
        color: $colorCru-white;
      }
    }
  }

  .steps-mobile-progress {
    margin-top: 15px;
  }

  .steps-single-mobile {
    width: 33.3333%;
    height: 3px;
    margin: 0;
    background: $colorCru-border;
    float: left;

    &.on {
      background: $colorCru-gold;
    }
  }
}

.border-bottom-small {
  padding-bottom: 1em;
  border-bottom: 1px solid $colorCru-border;
  margin-bottom: 1em;
}

.cart-tab {
  display: none;

  &.on {
    display: block;
  }
}

.tab-btn {
  margin-bottom: $gutter * 0.25;
  background: $colorCru-white;

  input[type="radio"] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
  }

  img {
    display: inline-block;
    max-height: 50px;
    margin-left: 20px;
  }

  @include respond-to(extrasmall) {
    margin-bottom: 7.5px;
  }

  &.on {
    border-color: $colorCru-border;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    background-color: #f1f1f1;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: #595455;
  }
}

.btn-block-mobile {
  @include respond-to(extrasmall) {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    float: none !important;
  }
}

.form-group-inline {
  .label-block {
    display: block;
  }

  label.radio-inline {
    margin-top: 10px;
    font-size: initial;
    font-weight: normal;
    text-transform: none;
  }

  .radio-inline + .radio-inline, 
  .checkbox-inline + .checkbox-inline {
    @include respond-to(medium) {
      // margin-left: 0;
    }
  }
}

.checkbox-inline {
  input[type="checkbox"] {
    position: relative;
  }
}

.circle-it {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid $colorGrayscale-text;
  border-radius: 40px;
  color: $colorGrayscale-text;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;

  .fa {
    vertical-align: middle;
  }
}

.billing-address-wrap  {
  display: none;
  margin-top: 20px;

  &.on {
    display: block;
  }
}

.billing-address-new-wrap {
  display: none;

  &.on {
    display: block;
  }
}

.checkbox-indent {
  margin-left: 20px;
}

.panel-secondary {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  background: #eee;
}

.btn-panel-head {
  padding: 0 $gutter * 0.5;
  font-size: 15px;
  font-weight: 600;
}

.bank-explain-wrap {
  overflow: hidden;
  height: 100%;
  max-height: 0;
  transition: 0.5s all;

  &.on {
    max-height: 600px;
  }

  @include respond-to(extrasmall) {
    h4{
      padding-top: 20px;
    }
  }
}

.form-group.has-error.no-wrap{
  label{
    white-space: nowrap;
  }
}

.cover-fees-text {
  margin-left: 5px;
}
