// Cart Specific Styles
.giftsum-actions {
  width: 20%;
  text-align: left;

  .btn-giftsum-action {
    padding-top: 0;
    padding-bottom: 0;
    color: inherit;

    @include respond-to(extrasmall) {
      border: 1px solid $input-border;
      border-radius: 3px;
      margin-right: 8px;
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      background: #eee;
    }
  }  
}


.giftsum-table {
  margin-bottom: 0;

  thead {
    @include respond-to(extrasmall) {
      display: none;
    }

    tr {
      th {
        border-bottom-color: $colorCru-border;
        font-size: 0.9em;
        font-weight: 200;
        text-transform: uppercase;
      }
    }
  }

  .giftsum-gift-row {
    @include respond-to(extrasmall) {
      display: block;
      padding-top: 1em;
      border-top: 2px solid $colorCru-border;
      margin-top: 1em;

      &:first-child {
        margin-top: 0;
      }
    }

    td {
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 2px solid $colorCru-border;

      @include respond-to(extrasmall) {
        display: block;
        width: 100%;
        padding: 0;
        border: none;
      }
    }

    .giftsum-profile {
      height: auto;
      margin-right: 15px;

      @include respond-to(extrasmall) {
        display: none;
      }
    }

    .giftsum-title {
      display: block;

      @include respond-to(extrasmall) {
        display: inline-block;
      }
    }

    .giftsum-detail {
      display: block;
      color: $colorGrayscale-slate;
      font-family: $data-font;
      font-size: 0.75em;

      @include respond-to(extrasmall) {
        display: inline-block;
      }
    }

    .giftsum-actions {
      font-size: 0.8em;
      text-transform: uppercase;

      @include respond-to(extrasmall) {
        text-align: left;

        .sep {
          display: none;
        }
      }
    }

    .giftsum-about {
      font-family: $data-font;
    }
  }

  tfoot {
    tr {
      @include respond-to(extrasmall) {
        display: block;
        width: 100%;
        border: none;
      }

      @include mq(500px, max) {
        td {
          text-align: center;

          .btn {
            display: block;
          }

          .pull-left, .pull-right {
            float: none !important;
          }

          .checkout-cta {
            margin-top: 16px;
          }
        }
      }

      td {
        padding: 40px 0 0;
        vertical-align: middle;

        @include respond-to(extrasmall) {
          display: block;
          width: 100%;
          border: none;
        }
      }

      .checkout-cta {
        margin-left: $gutter;

        @include respond-to(extrasmall) {
          padding-top: 0;
          margin-left: 0;
        }
      }
    }
  }

  .giftsum-line {
    display: block;
    line-height: 1.5em;

    strong {
      font-family: $data-font;
    }
  }
}

.giftsum-table-cart {
  .th-title-amount {
    font-size: 14px;
  }

  .td-frequency {
    font-size: 14px;
    text-align: right;
  }

  .giftsum-gift-row {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .giftsum-about {
      font-weight: bold;
    }
  }

  .giftsum-line {
    font-size: 16px;
  }

  tfoot {
    tr {
      td {
        padding-top: 15px;
      }
    }
  }
}

product-config-modal .modal-footer {
  padding: 15px 29px;
}

@media screen and (max-width: 549px) {
  .btn-block-xs {
    display: block;
    width: 100%;
    &.pull-right {float: none !important;}
    + .btn-block-xs {margin-top: 10px;}
  }

  .modal-footer .btn.btn-block-xs + .btn.btn-block-xs {
    margin-left: 0;
  }
}

