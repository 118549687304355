/*------------------------------------*\
    #LOGO
\*------------------------------------*/

.cruLogo {
    background: url("//www.cru.org/images/cru-logo.svg") no-repeat center center;
    display: block;
    width: 100%;
    height: 100%;
}

/**
 * This provides a fallback for browsers that do not support svg.
 */
.no-svg .cruLogo {
    background-image: url("//www.cru.org/images/cru-logo.png");
}
