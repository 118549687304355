/*------------------------------------*\
    #SUMMER-MISSIONS
\*------------------------------------*/

.pageNav {
    position: relative;
    top: 100px;
    z-index: 1;
    text-align: center;

    @include mq(384px) {
        top: 73px;
    }

    @include mq(1023px) {
        top: 65px;
    }

    .btn-group {
        @include mq($nav-break-max, max) {
            width: auto;
        }
    }
}

.post-article .pageNav {
    top: $base-spacing-unit;
}

.pageNav-link {
    @include mq(384px, max) {
        font-size: 64%;
    }

    @include mq(500px, max) {
        padding-left: 8px;
        padding-right: 8px;

        @include mq(385px) {
            font-size: 75%;
        }
    }

    @include mq(1024px) {
        padding: 8px 24px;
        border-radius: 3px;
    }
}

.summerMissions .post-nav li {
    display: block;
}

.summerMissions-map {
    height: 500px;
}
