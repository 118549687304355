/*------------------------------------*\
    $CRUICONS
\*------------------------------------*/
/**
 * As of now this is where we will be placing our icon fonts.
 * Freight Sans Pro will be served up from Typekit.
 */

@font-face {
    font-family: "cruicons";

    src: url("/assets/fonts/cruicons.eot");
    src: url("/assets/fonts/cruicons.eot#iefix") format("embedded-opentype"),
    url("/assets/fonts/cruicons.woff") format("woff"),
    url("/assets/fonts/cruicons.ttf") format("truetype"),
    url("/assets/fonts/cruicons.svg#cruicons") format("svg");

    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'cruicons';
    src: url('/assets/font/cruicons.svg?54093663#cruicons') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
    display: inline-block;
    font-family: "cruicons";
    font-style: normal;
    font-weight: normal;
    speak: none;

    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-info-circled:before,
%icon-info-circled:before {
    content: "\e808";
} /* '' */

.icon-right-circle:before,
%icon-right-circle:before {
    content: "\e800";
} /* '' */

.icon-left-arrow:before,
%icon-left-arrow:before {
    content: "\e80e";
} /* '' */

.icon-right-arrow:before,
%icon-right-arrow:before {
    content: "\e80d";
} /* '' */

.icon-up-arrow:before {
    content: "\e80c";
} /* '' */

.icon-twitter:before {
    content: "\e801";
} /* '' */
.icon-facebook:before {
    content: "\e802";
} /* '' */
.icon-linkedin:before {
    content: "\e810"; //change the # 
} /* '' */
.icon-gplus:before {
    content: "\e803";
} /* '' */
.icon-hamburger:before {
    content: "\e805";
} /* '' */
.icon-location:before {
    content: "\e806";
} /* '' */
.icon-mail:before {
    content: "\e807";
} /* '' */
.icon-cancel-circled:before {
    content: "\e809";
} /* '' */
.icon-down-arrow:before {
    content: "\e80f";
} /* '' */
.icon-tag:before {
    content: "\e811";
} /* '' */

.icon-comment-1:before {
    content: "\e812";
} /* '' */


