.slick-related-carousel {
  margin-right: -15px;
  margin-left: -15px;
}

.slick-slide {
  .panel {
    margin-bottom: 0;
  }

  .u-linkClean {
    color: $colorCru-gray;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
  }

  .icon--readNext::after {
    color: $colorCru-gray;
  }

  img {
    width: 100%;
  }
}

.slick-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 38px;
  height: 60px;
  border: 0;
  background: $colorCru-white;
  background-image: none !important;
  color: $list-group-link-color;
  line-height: 60px;
  opacity: 1 !important;
  transform: translateY(-50%);
  vertical-align: middle;

  &.slick-next {
    right: -15px;
    left: auto;
    overflow: hidden;
    box-shadow: -3px 0 5px $input-border;
    text-indent: -138px;

    &::after {
      display: inline-block;
      margin-top: -1px;
      margin-left: 110px;
      content: "\f105";
      font-family: fontawesome, "Font Awesome 5 Pro", sans-serif;
      font-size: 38px;
      text-indent: 0; 
    }
  }

  &.slick-prev {
    right: auto;
    left: -15px;
    overflow: hidden;
    box-shadow: 3px 0 5px $input-border;
    text-indent: -138px;

    &::after {
      display: inline-block;
      margin-top: -1px;
      margin-left: 80px;
      content: "\f104";
      font-family: fontawesome, "Font Awesome 5 Pro", sans-serif;
      font-size: 38px;
      text-indent: 0;     
    }
  }
}
