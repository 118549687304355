.search-head {
  padding: $gutter * 2 0;
  background: $colorGrayscale-pebble;

  h1 {
    font-size: 32px;

    .search-count {
      display: inline-block;
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5em;
  }

  a {
    text-decoration: underline;
  }

  .search-term {
    display: inline-block;
    font-weight: 600;
  }

  form {
    max-width: 100%;
    margin: 0 auto $gutter auto;

    button.submit-search {
      height: 50px;
      padding: 0 50px;
      border-radius: $border-radius;
      margin-top: 0;

      /* Discover more button BG: */
      background: $colorCru-gold;
      font-size: 20px;
      line-height: 48px;
      vertical-align: top;
    }

    .input-group {
      display: inline-block;
      width: 60%;
      margin-right: $gutter * 0.25;
      margin-left: auto;

      @media screen and (max-width: 465px) {
        width: 100%;
      }

      input {
        display: inline-block;
        height: 50px;
        padding: 0 13px;
        border: none;
        border-radius: $border-radius !important;
        border-right-width: 0;
        box-shadow: none;
        float: left;
        line-height: 22px;
      }

      button {
        display: inline-block;
        padding: 14px;
        border: none;
        border-radius: 0 $border-radius $border-radius 0;
        background-color: $colorCru-white;
        box-shadow: none;
        float: right;
        line-height: 22px;

        i {
          color: $colorCru-gray;
        }
      }
    }
  }
}

.search-section {
  margin-bottom: $gutter * 2;
}

.search-result-block {
  a.result {
    position: relative;
    display: block;

    &:hover {
      text-decoration: none;

      &::before {
        opacity: 1;
      }
    }

    &::before {
      position: absolute;
      top: 10px;
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: $colorCru-gold;
      content: "";
      opacity: 0;
      transition: 0.25s all;
    }
  }
}

.search-section-head {
  margin: $gutter 0;

  h2 {
    height: 32px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }

    &::before,
    &::after {
      display: block;
      width: 38%;
      height: 1px;
      margin-top: 16px;
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      background: #bbb;
      content: "";

      @media screen and (max-width: 991px) {
        width: 32%;
      }
    }

    &::before {
      float: left;
    }

    &::after {
      float: right;
    }
  }

  &.big-words {
    h2 {
      &::before,
      &::after {
        @media screen and (max-width: 768px) {
          width: 18%;
        }
      }
    }
  }
}

.margin-top-30 {
  margin-top: $gutter;
}

.is-tabs {
  margin-bottom: $gutter;
}

.is-toggle {
  padding: 6px 8px;
  margin-top: -2px;
  line-height: 1em;
}

.is-toggle-wrap {
  transition: 0.5s all;

  &.open {
    max-height: 1000px;
    padding-bottom: $gutter;
  }

  @media screen and (max-width: 991px) {
    overflow: hidden;
    max-height: 1px;
  }
}

.is-type-links {
  li {
    display: block;
    line-height: 1.5em;

    a {
      display: block;
    }

    &.active {
      position: relative;
      font-weight: bold;

      &::before {
        position: absolute;
        top: 7px;
        left: -15px;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 15px;
        background: $colorCru-gold;
        content: "";
        transition: 0.25s all;

        @media screen and (max-width: 991px) {
          right: 0;
          left: auto;
        }
      }
    }
  }
}

.is-top {
  padding: $gutter * 1.5 0;
  background: $colorGrayscale-stone;

  h1 {
    font-size: 24px;
    text-align: center;
  }
}

.is-advanced {
  @media screen and (min-width: 991px) {
    padding-right: 20px;
  }

  h4 {
    padding-bottom: $gutter * 0.5;
    border-bottom: 2px solid $colorGrayscale-rock;
    margin-bottom: $gutter * 0.5;
  }
}

.is-search-main {
  max-width: 740px;
  margin: 0 auto;

  input[type="search"] {
    height: 54px;
    padding: 10px;
    border-width: 1px;
    font-size: 18px;
  }

  .btn {
    height: 54px;
    padding-right: $gutter * 0.9;
    padding-left: $gutter * 0.9;
    margin-left: 5px !important;
  }
}

.input-subtle {
  border-width: 1px;
}


.is-results-divider {
  height: 32px;
  margin-top: $gutter;
  margin-bottom: $gutter;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  &::before,
  &::after {
    display: block;
    width: 30%;
    height: 1px;
    margin-top: 16px;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    background: #bbb;
    content: "";

    @media screen and (max-width: 991px) {
      width: 20%;
    }
  }

  &::before {
    float: left;
  }

  &::after {
    float: right;
  }
}

.is-row {
  display: flex;
  align-items: center;
  padding-bottom: $gutter * 0.65;
  border-bottom: 1px solid $colorGrayscale-stone;
  margin-bottom: $gutter * 0.65;
  column-gap: $gutter * 0.5;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
  }

  .is-row-thumb {
    min-width: 90px;

    img {
      max-width: 90px;
    }
  }

  .is-row-meta {
    flex: 1;

    span {
      display: block;
    }

    .is-row-title {
      color: $colorCru-gray;
      font-size: 115%;
      font-weight: 500;
      line-height: 120%;
    }

    .is-row-num {
      color: $colorCru-gray;
      font-size: 100%;
    }
  }
}

.nav-search-tabs {
  border-top: none;
  margin-top: 0;
  text-align: center;

  li {
    float: none;
  }

  > li.active {
    > a {
      border: none;
      border-bottom: 2px solid $colorCru-gold;
      background: none;

      &:hover,
      &:focus {
        border-width: 0 0 2px 0;
        border-bottom: 2px solid $colorCru-gold;
        background: none;
      }
    }
  }

  > li {
    > a {
      font-family: $data-font;
      font-size: 18px;
      font-weight: 600;
      text-transform: capitalize;
      transition: 0.25s all;

      @media screen and (max-width: 550px) {
        padding: 8px 10px;
        font-size: 14px;
      }

      &:hover {
        border-bottom: 2px solid $colorGrayscale-rock;
      }
    }
  }
}
