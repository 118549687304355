$headerHeight: 51px;




/* basic Menu Styles
===================================================*/
#menu {
	display: block;
	width: 300px;
	min-height: 100%;
	top: 0;
	position: absolute;
	background: $colorGrayscale-pebble;
	//color: #fff;
    -webkit-transition: right .25s;
    transition: right .25s;
    z-index: 9999;
}

#menu  .toggle {
	right: 300px;
  font-weight: normal;
}

#menu .toggle--close {
    color: $colorCru-gray;
    margin-left: 200px;
    margin-top: 20px;
    margin-bottom: 15px;
    &:hover,
    &:active,
    &:focus {
        color: $colorCru-gold;
    }
}

#menu .toggle--open {
    color: $colorCru-gray;
    margin-left: 200px;
    margin-top: 20px;
    margin-bottom: 15px;
		    visibility: hidden;
    &:hover,
    &:active,
    &:focus {
        color: $colorCru-gold;
    }
}

/* Hiding and showing stuff
===================================================*/

/* Hiding the checkboxes so only the labels are visible */
#toggle,
input[id^="dropdown"],
ul[class^="dropdown"] {
	display: none;
}

/* Pushing the Menu off the canves */
#menu {
	right: -300px; /* must be the same as the width of the menu */
	-webkit-transition: right .25s;
	transition: right .25s;
}

/* Showing the menu then the Checkbox is checked */
#toggle:checked + #menu {
	right: 0;
    -webkit-transition: right .25s; /* Safari 3.1 to 6.0 */
    transition: right .25s;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 0 9em rgba(0, 0, 0, 0.76);
}

input[id^="dropdown"]:checked + ul[class^="dropdown"] {
	height: auto;
	display: block;
}

#toggle{
    display: none;
}



.card {
    box-shadow: 0 0 1px 0 rgba(44,54,67,0.1);
    position: relative;
    margin-bottom: 10px;
    background-color: $colorGrayscale-sand;
    border-radius: 2px;

}


.card-shadow {
  z-index: 1;
    position: relative;
    margin-bottom: 5px;
    //color: $colorGrayscale-sand;
    border-radius: 2px;
    -webkit-transition: all 250ms;
      -moz-transition: all 250ms;
      transition: all 250ms;

      //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      &:hover {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
            background-color: $colorGrayscale-pebble;


      }
}


.card--linked {
    margin-top: 10px;
    position: relative;

    &:before {
        border-radius: 0 4px 4px 0;
        box-shadow: 15px 0 0 #fff;
        left: 4px;
        width: 17px;
    }

    &:after {
        border-radius: 4px 0 0 4px;
        right: 4px;
        width: 130px;

        @include mq(1200px) {
            width: 169px;
        }
    }

    &:before,
    &:after {
        position: absolute;
        z-index: 10;
        background-color: #f7f7f7;
        height: 11px;
        bottom: 100%;
        content: "";
        display: block;
    }

}

.card-title {
    display: block;
    padding: 13px 15px;
    margin-bottom: 0;
    line-height: 1.3;
    border-bottom: 1px solid #dbe6ec;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0;
    color: $colorText--muted;

    @include font-size(12px);
}

.card-link--section{
    font-size: 70%;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $colorCru-blueDeep;
    background-color: #f9f9f9;
    display: block;
    line-height: 1.2em;
    padding: 15px 30px 15px 30px;
    position: relative;
    border-bottom: 1px solid rgba(219,230,236,0.5);

&:hover,
&.is-active {
    background-color: #f5f5f5;
}

    &:hover {
        text-decoration: none;
    }

    &.is-active {
        border-left: 2px solid #f9b625;
        color: #3b444f;
        padding-left: 13px;
    }
}

.card-link{
    font-size: 90%;
    font-weight: 500;
   text-transform: none;
   text-indent: 0;
    color: $colorText;
    display: block;
    line-height: 1.2em;
    padding: 12px 25px 12px 15px;
    position: relative;
    border-bottom: 1px solid rgba(219,230,236,0.5);

&:hover,
&.is-active {
    background-color: #f5f5f5;
}

    &:hover {
        text-decoration: none;
    }

    &.is-active {
        border-left: 2px solid #f9b625;
        color: #3b444f;
        padding-left: 13px;
    }
}

.icon--link{
    &:after {
      //  @extend %icon--chevronRight;
        background-position: 0 20%;
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        pointer-events: none;
        right: 12px;
        top: 16px;
        content: "";
        background-size: cover;
        pointer-events: none;
    }
}

.swatch {
    background-color: #f9f9f9;
    margin-bottom: $base-spacing-unit;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(0,0,0,0.4);

    @include font-size(14px);
}


.swatch-preview {
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    display: block;
    float: left;
    height: 60px;
    width: 100%;
    margin: 0 0.8em 0.5em 0;
}


.lunchMenu {
    z-index: 5000;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15em;
    max-width: 75%;
    overflow-y: auto;
    background: #3d3e3f;
    padding: 4.8125em 0 1.75em 0;

    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;

    .js & {
        left: auto;
        right: 100%;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    .is-open--nav & {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

.nav-open,
.lunchMenu {
    @include mq(994px) {
        display: none
    }
}













.is-open--lunchMenu body {
    overflow: hidden;
    -webkit-overflow-scrolling: none;
    max-height: 100%;
}

.globalNavigation {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $colorGrayscale-pebble;
    display: inline-block;
    vertical-align: top;
    //height: $headerHeight;

    @include mq(896px) {
        width: 90%;
        position: relative;
        display: none;
    }
}





.lunchMenu-open {
     text-transform: uppercase;
    position: absolute;
    right: 0;
    top: 0;
    color: #3a3a3a;
    margin: 0;
    padding: 0 18px;

    @include animation-duration(1s);
    @include animation-timing-function(ease-in-out);

    @include mq(840px) {
        display: none;
    }

    .is-open--lunchMenu & {
        display: none;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        line-height: $headerHeight;
    }
}

.lunchMenu-close {
    text-transform: uppercase;
    position: absolute;
    right: 0;
    top: 0;
    color: #3a3a3a;
    margin: 0;
    padding: 0 18px;
    display: none;

    .is-open--lunchMenu & {
        display: block;
    }

    span {
        display: inline-block;
        vertical-align: middle;
        line-height: $headerHeight;
    }
}

.lunchMenu {
    position: fixed;
    z-index: -100;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: $headerHeight 0 25px;
    background: #fff;
    overflow-x: hidden;
    -ms-overflow-style: none;
    visibility: hidden;

    @include opacity(0);
    @include backface-visibility(hidden);
    @include transition(opacity 0.2s ease);

    .is-open--lunchMenu & {
        visibility: visible;
        z-index: 1000;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        @include opacity(1);
    }

    .btn-group {
        width: 100%;
    }

    .btn-group,
    .listBlock,
    .globalSearch-form {
        @include transition-delay(0.25s);
    }
}

.lunchMenu-link {
    width: 100%;
    padding: 0 18px;
    line-height: 60px;
    border: 1px solid transparent;
    border-bottom-color: #e7e7e7;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    color: $colorText;


    .has-submenu{
        //border-top-color: #e7e7e7;
        color: $colorText;
        background: $colorGrayscale-stone;
    }

    .dropdownWrap:first-of-type &{
        //border-top-color: #e7e7e7;
        color: $colorText;
        background: $colorGrayscale-stone;
    }
}

.dropdownWrap .lunchMenu-link--primary {
    width: 85%;
}
/**
.globalSearch-form {
    height: 62px;

    @include mq(839px, max) {
        width: 100%;
    }

    .form-group,
    .globalSearch-btn {
        float: left;
    }

    .form-group {
        @include mq(839px, max) {
            width: 85%;
        }
    }

    .globalSearch-btn {
        @include mq(839px, max) {
            width: 15%;
            height: 62px
        }
    }

    .form-group .globalSearch-input {
        @include mq(839px, max) {
            width: 100%;
            height: 62px;
            background-color: #f4f4f4;
            box-shadow: none;
        }
    }
}
*/
.globalSearch-form{

    .form-group {
        width: 100%;
        vertical-align: middle;
    }

    &,
    .globalSearch-item {
        height: 62px;
    }

    .globalSearch-item {
        display: inline-block;
        vertical-align: middle;
    }

    .globalSearch-input {
        width: 70%;
        padding: 0 18px;
        box-shadow: none;

        @include mq(550px) {
            width: 80%;
        }
    }

    .globalSearch-btn,
    .globalSearch-select {
        width: 15%;
        border-left: 1px solid #e7e7e7;
        border-color: rgba(216, 216, 216, 0.35);

        @include mq(550px) {
            width: 10%;
        }
    }

    .globalSearch-select {
        text-indent: -9999px;
        background-position: center center;
        box-shadow: none;

        @include appearance(none);
    }
}

.lunchMenu {
    .dropdownWrap {
        > .dropdown {
            overflow: hidden;
        }
    }

    .dropdown {
        visibility: hidden;
        height: 0;
        max-height: 0;

        @include opacity(0);
    }

    .dropdownWrap > .dropdown.is-open {
        overflow: initial;
        border-bottom: 1px solid #e7e7e7
    }

    .dropdown.is-open {
        height: auto;
        max-height: 100%;
        visibility: visible;

        @include opacity(1);
    }
}


.dropdown .nav-item {
    padding: 6px 18px;
    text-transform: none;
    font-weight: 400;

    &:active,
    &:focus,
    &:hover{
        background-color: #f7f7f7
    }
}

.dropdown-actionBtn {
    position: relative;
    display:  inline-block;

    span {
        position: relative;
        top: 15px;
        float: right;

        &:before {
            @include transition(rotate 0.2s ease);
            @include transform(rotate(90deg));
        }

        &:before,
        &:after {
            position: absolute;
            right: 0;
            content: '';
            width: 15px;
            height: 2px;
            background-color: #000;
            display: block;
        }
    }

    .is-open--dropdown & {
        span {
            &:before {
                @include transform(rotate(0));
            }
        }
    }
}

.lunchMenu-icon {
    background-position: center right;
    padding-right: 60px;
}

.lunchMenu .actionBtn {
    height: 60px;
    border-radius: 0;
}

.globalSearch-choices {
    visibility: hidden;
    width: 100%;
    font-size: 19px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.15);
    height: 0;

    @include opacity(0);
    @include transition(height 0.25s ease);
    @include box-shadow(0 6px 12px rgba(0,0,0,0.175));

    &.is-open--searchChoices {
        position: absolute;
        visibility: visible;
        z-index: 1000;
        height: 90px;
        @include transition(height 0.25s ease);
        @include opacity(1);

    }
}




/* Mobile Sub-Menu Styles
===================================================*/
#menu-subnav {
  display: block;
	width: 300px;
	min-height: 100%;
	top: 0;
	position: absolute;
	background: $colorGrayscale-pebble;
	//color: #fff;
    -webkit-transition: right .25s;
    transition: right .25s;
    z-index: 9999;
}

#menu-subnav  .toggle {
	right: 300px;
  font-weight: normal;
}

#menu-subnav .toggle--close {
    color: $colorCru-gray;
    margin-left: 200px;
    margin-top: 20px;
    margin-bottom: 15px;
    &:hover,
    &:active,
    &:focus {
        color: $colorCru-gold;
    }
}

#menu-subnav .toggle--open {
    color: $colorCru-gray;
    margin-left: 200px;
    margin-top: 20px;
    margin-bottom: 15px;
    &:hover,
    &:active,
    &:focus {
        color: $colorCru-gold;
    }
}

/* Hiding and showing stuff
===================================================*/

/* Hiding the checkboxes so only the labels are visible */
#toggle-subnav,
input[id^="dropdown"],
ul[class^="dropdown"] {
	display: none;
}

/* Pushing the Menu off the canves */
#menu-subnav {
	right: -300px; /* must be the same as the width of the menu */
}

/* Showing the menu then the Checkbox is checked */
#toggle-subnav:checked + #menu-subnav {
  right: 0;
  top: 86px;
    -webkit-transition: right .25s; /* Safari 3.1 to 6.0 */
    transition: right .25s;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 0 9em rgba(0, 0, 0, 0.76);
}

input[id^="dropdown"]:checked + ul[class^="dropdown"] {
	height: auto;
	display: block;
}

#toggle-subnav{
    display: none;
}

/* Pushing the Menu off the canves */
#menu-subnav {
    right: -300px; /* must be the same as the width of the menu */
    -webkit-transition: right .25s;
    transition: right .25s;
}

/* Showing the menu then the Checkbox is checked */
#toggle-subnav:checked + #menu-subnav {
    right: 0;
    -webkit-transition: right .25s; /* Safari 3.1 to 6.0 */
    transition: right .25s;
}
