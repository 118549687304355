/*------------------------------------*\
    #CAMPUS-LOCATOR
\*------------------------------------*/
/**
 * Used for background colors at the moment, text is either in
 * the default text color or white. You can extend %text-white
 * found in the _utilities.scss file if you wish to change the
 * text color.
 */

.state-select-results {
    small {
        color: $colorText;
    }
}

.contact__titles {
    @include mq($screen-sm-max, max) {
        display: none !important;
    }
}

.contact__title-name {
    font-size: 80% !important;
}


.ui-helper-hidden-accessible {
    @extend %u-hiddenVisually;
}

.ui-autocomplete {
    border: 1px solid $colorCru-gray;

    width: 350px;
    padding-left: 0;

    li {
        list-style: none outside none;

        a {
            background-color: $colorCru-gray;
            color: $colorCru-white;

            display: block;
            padding: 5px 13px;
            cursor: pointer;
            text-decoration: none;

            // scss-lint:disable NestingDepth
            &:hover {
                color: $colorCru-gold;
            }
            // scss-lint:enable NestingDepth
        }
    }
}
