/*------------------------------------*\
    #VARIABLES/BUTTONS
\*------------------------------------*/

$btn-font-size:           16px;
$btn-border-size:         1px;

$btn-secondary-color:     $colorCru-white;
$btn-secondary-bg:        $colorCru-blueDeep;
$btn-secondary-border:    $colorCru-blueDeep;

$btn-subtle-color:        #6d6e71;
$btn-subtle-bg:           #f4f4f4;
$btn-subtle-border:       #e7e7e7;
$btn-subtle-state-color:  $colorCru-white;
$btn-subtle-state-bg:     #666062;
$btn-subtle-state-border: #595455;
