.carousel-container {
  margin-top: 20px;

  h3,
  h5 {
    text-align: center;
  }

  table {
    width: 100%;

    td {
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
    }

    td:first-of-type {
      text-align: left;
    }

    td:last-of-type {
      text-align: right;
    }

    img {
      width: auto;
      max-height: 60px;
    }
  }
}
