// Give Specific Styles
$colorGrayscale-sand: #f4f4f4;
$colorGrayscale-pebble: #f0f0f0;
$colorGrayscale-stone: #e7e7e7;
$colorGrayscale-rock: #b9b9b9;
$colorGrayscale-slate: #909b9c;
$colorCru-border: #ddd;
$data-font: "Source Sans Pro", "Helvetica Neue", helvetica, sans-serif;

.data-font {
  font-family: $data-font;
}

@media screen and (max-width: 750px) {
  .container {
    width: auto;
  }
}

@media screen and (max-width: 991px) {
  a.btn {
    white-space: normal;
  }
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0;
}

.small-text {
  font-size: 12px;
}

.u-textTransformNone {
  text-transform: none !important;
}

#menu {
  display: none;
}

$gutter: 30px;

.btn-transparent {
  background: transparent !important;

  &:hover, &:active, &:focus, &:visited {
    background: transparent !important;
  }
}

.border0 {
  border: none;
}

html {
  height: 100%;
  font-family: $data-font;
}

body {
  height: 100%;
}

.touchui {
  height: auto;
}

hr.vertical-divider {
  display: inline-block;
  width: 1px;
  height: 100%;
  border: none;
  margin: 0;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  background: #e9e9e9;
}

hr.horizontal-divider {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
}

.container,
.container-fluid {
  &:focus {
    outline: none;
  }
}

@media (max-width: 550px) {
  .container {
    width: 100%;
  }
}

.panel-plain {
  border: none;
  box-shadow: none;
}

.panel-nomargin {
  margin: 0;
}

.panel .panel-box {
  padding: 15px 29px;
  border-top: 1px solid $colorCru-border;
}

.panel-name {
  margin-bottom: 1em;
}

.panel.sign-in-panel {
  padding-top: 30px;
  padding-bottom: 30px;

  @include respond-to(supertiny) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sign-in-section {
    padding: 45px 50px;

    @include respond-to(supertiny) {
      padding: 30px;
    }

    .section-description {
      color: $colorCru-gray;
      font-size: 125%;
      font-weight: normal;
      line-height: 125%;
    }
  }

}

.secondary-detail {
  position: relative;
  max-width: 40%;
  max-height: 345px;

  & > img {
    max-height: 345px;
  }
}

.self-service {
  margin-top: 29px;
  font-size: 125%;
}

.trigger {
  padding: 0;
  border: none;
  background: none;
  font-weight: 600;
  line-height: 1em;
}

.collapse-indicator {
  &::after {
    display: inline-block;
    margin-top: 1px;
    margin-left: 1.5px;
    content: "\002B";
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    transition: all 0.25s;
  }

  &.collapsed {
    &::after {
      transform: rotate(0deg);
    }
  }
}

.collapse-indicator-row {
  .trigger::after {
    display: inline-block;
    margin-top: 1px;
    margin-left: 5.5px;
    content: "\002B";
    font-size: 125%;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    transition: all 0.25s;
  }

  &.collapsed {
    .trigger::after {
      transform: rotate(0deg);
    }
  }
}

.row {
  &.vdivide [class*='col-']:not(:last-child)::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 1px;
    min-height: 70px;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    background: #e0e0e0;
    content: "";
  }

  @media (max-width: 991px) {
    &.vdivide [class*='col-']:not(:last-child)::after {
      position: relative;
      top: 45px;
      right: 0;
      bottom: 0;
      display: block;
      width: 100%;
      min-height: 1px;
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      background: #e0e0e0;
      content: "";
    }
  }

  @include respond-to(supertiny) {
    &.vdivide [class*='col-']:not(:last-child)::after {
      top: 30px;
    }
  }

  &.row-eq-height {
    display: flex;
  }
}

.panel-full-height {
  height: 100%;
  margin-bottom: 0;
}

.give-available-photos-container {
  margin-top: 20px;

  .give-thumbs-container {
    $column-count: 3;
    $grid-gap: $gutter * 0.75;

    display: grid;
    align-items: stretch;
    justify-content: stretch;
    gap: $grid-gap;
    grid-auto-rows: minmax(90px, auto);
    grid-template-columns: repeat(
      $column-count,
      calc((100% - #{$grid-gap * ($column-count - 1)}) / #{$column-count})
    );

    @include respond-to(supertiny) {
      $column-count: $column-count - 1;

      grid-template-columns: repeat(
        $column-count,
        calc((100% - #{$grid-gap * ($column-count - 1)}) / #{$column-count})
      );
    }
  }

  h3,
  h5 {
    text-align: center;
  }

  .add-trigger {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background: $colorCru-gray;
    color: $colorCru-white;
    cursor: pointer;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }

  .cover-thumb {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .no-cover {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      color: $colorCru-gray;
      transform: translateY(-50%);
    }
  }

  .cover-thumb.processing {
    overflow: hidden;
    height: 98.25px;
    object-fit: contain;
    object-position: center;
  }

  .cover-thumb.processing img {
    opacity: 0.5;
  }

  .cover-thumb-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 28px;
    height: 28px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.5);
    color: $colorCru-white;
    line-height: 28px;
    text-align: center;
    transition: 0.25s all;

    &:hover {
      background: rgba(0, 0, 0, 1);
    }
  }

  input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    &:checked + label {box-shadow: 0 0 0 3px $colorCru-gold;}
  }
  label {margin-bottom: 0;}
  img {display: inline-block;}
}

.panel-cover-wrap {
  position: relative;
}

.edit-trigger {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  background: $colorCru-gray;
  color: $colorCru-white;
  cursor: pointer;
  font-size: 24px;
  line-height: 45px;
  text-align: center;

  &:hover {
    color: $colorCru-white;
  }
}

@media screen and (max-width: $sm - 1) {
  .prod-edit-btns{
    &.text-right {
      margin-top: $gutter * 0.25 - 4;
      text-align: left;
    }
    .btn {margin-top: 4px;}
  }
}

.edit-trigger-text {
  position: static;
  display: block;
  width: 30px;
  height: 30px;
  margin-left: -44px;
  float: left;
  font-size: 16px;
  line-height: 30px;

  &.inline {
    display: inline-block;
    margin: 0 $gutter * 0.5;
    float: none;
    vertical-align: middle;
  }
}

.signature-image {
  max-width: 100%;
  max-height: 100px;
}

.upload-drag-target {
  border: 2px dashed $colorCru-border;
  border-radius: $gutter * 0.5;
}

.margin-bottom {
  margin-bottom: 2.5em;
}

.margin-top {
  margin-top: 2.5em;
}

.well-plain {
  padding: 30px;
  border: none;
  background: none;
  box-shadow: none;
}

.border-right {
  border-right: 1px solid lighten($colorGrayscale-text, 60%);

  @include respond-to(small) {
    border: none;
  }

  @include respond-to(extrasmall) {
    border: none;
  }

  @include clearfix;
}

.border-bottom-small {
  @include respond-to(small) {
    padding-bottom: 1.5em;
    border-bottom: 1px solid lighten($colorGrayscale-text, 60%);
    margin-bottom: 1.5em;
  }

  @include respond-to(extrasmall) {
    padding-bottom: 1.5em;
    border-bottom: 1px solid lighten($colorGrayscale-text, 60%);
    margin-bottom: 1.5em;
  }

  @include clearfix;
}

.border-top-small {
  padding-top: 1.5em;
  border-top: 1px solid lighten($colorGrayscale-text, 60%);
  margin-top: 1.5em;

  @include clearfix;
}

.title-margin {
  margin: 1.5em 0;

  @include respond-to(small) {
    margin: 0.75em auto;
  }

  @include respond-to(extrasmall) {
    margin: 0.75em auto;
  }
}

.sign-in-leadin {
  max-width: 265px;
  margin: 1em auto;
  font-size: 18px;
  line-height: 135%;
  text-align: center;
}

.radio-method,
.radio-custom-amount {
  label {
    font-weight: normal;
  }

  .expiring {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: red;
    font-size: 1em;
    line-height: 1em;
    text-indent: 60px;
  }

  img {
    display: inline-block;
    margin-right: $gutter * 0.5;
    vertical-align: middle;
  }

  input[type="radio"] {
    position: static;
    display: inline-block;
    margin: 0;
    margin-right: $gutter * 0.5;
    float: none;
    vertical-align: 0%;
  }
}

.btn-xs,
.btn-sm {
  font-size: 0.85rem;
  font-weight: 700;
}

.table-payment-history {
  thead {
    th {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  tbody {
    td {
      height: 75px;
      min-height: 75px;
      vertical-align: middle !important;

      label {
        margin-bottom: 0;
      }

      .radio-method {
        label {
          float: left;

          strong {
            font-weight: 500;
          }
        }
      }

      &.status-pending {
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        color: red;
      }
    }
  }
}

.table-striped-dark > tbody > tr:nth-child(odd) {
  background: lighten($colorGrayscale-stone, 5%);
}


.modal-meta{
  display: block;
  margin-bottom: $gutter * 0.25;
  color: lighten($colorGrayscale-text, 40%);
  line-height: 1.15em;
}

.repeating-row {
  clear: both;
  line-height: 1em;

  &::before,
  &::after {
    display: block;
    clear: both;
    content: "";
  }

  .recipient-image {
    max-width: 100px;
  }

  .recipient-name {
    margin-top: $gutter * 0.15;
    margin-bottom: $gutter * 0.25;
  }

  .checkbox-right {
    text-align: right;

    label {
      padding: 0;
      font-weight: normal;
      text-align: right;
    }

    input[type="checkbox"], input[type="radio"] {
      margin-left: $gutter * 0.25;
    }
  }
}

@media screen and (min-width: 550px ) and (max-width: 991px ) {
  .split-row {
    width: 100%;
    margin-top: $gutter * 0.5;

    .split-col-left, .split-col-right {
      width: 45%;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .split-col-left {
      float: left;
    }

    .split-col-right {
      float: right;
    }
  }
}

.row-no-spacing {
  > [class*="col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.inline-form-group {
  margin-bottom: 0;

  label {
    display: block;
  }

  span.line-height {
    line-height: 44px;
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"] {
    display: inline-block;
    width: 175px;
    margin-right: $gutter * 0.5;
  }
}

.inline-block {
  display: inline-block;
}

.recent-recipient-row {
  margin-bottom: $gutter;
}

img.full-width {
  width: 100%;
}

.list-recurring {
  margin-bottom: 0;

  h4 {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 0.75em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .meta {
    font-size: 1em;
    line-height: 1.4em;
  }
}

.ecfa-logo {
  margin: 0;
}

.screenContent {
  padding-top: 35px !important;

  &:focus {
    outline: none;
  }
}

.screenContent-alt {
  background: $colorCru-white;
}

.panel,
.panel-body {
  p, ul {
    font-size: 100%;
  }
}

.strike {
  display: block;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;

  > span {
    position: relative;
    display: inline-block;
  }

  > span::before, > span::after {
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: $colorGrayscale-rock;
    content: "";
  }

  > span::before {
    right: 100%;
    margin-right: 15px;
  }

  > span::after {
    left: 100%;
    margin-left: 15px;
  }
}

.text-muted {
  color: lighten($colorGrayscale-text, 40%);
}

.socialIcon {
  @media screen and (max-width: 640px) {
    padding-right: $gutter * 0.35;
    padding-left: $gutter * 0.35;
  }
}

.footer-seals {
  text-align: center;

  img, div {
    display: inline-block;
    max-height: 70px;
    margin: 0 $gutter * 0.5;
    vertical-align: top;
    &:focus {outline: none;}
  }
  > div {margin-top: 6px;}
}

// Responsive alignment. Based off of https://github.com/twbs/bootstrap/blob/v4.0.0-alpha.5/scss/utilities/_text.scss#L11-L19
@media (min-width: $xs + 1) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

.saved-payment-methods {
  .row {
    label {
      padding-left: 15px;
    }
  }
}
