/*------------------------------------*\
    #POSITION UTILITIES
\*------------------------------------*/

// scss-lint:disable SingleLinePerProperty, SingleLinePerSelector, SpaceBeforeBrace

.u-posAbsolute { position: absolute !important; }
.u-posRelative { position: relative !important; }
.u-posStatic   { position: static !important; }

/**
 * 1. Make sure fixed elements are promoted into a new layer, for performance
 *    reasons.
 */
.u-posFixed { 
    position: fixed !important; 
    backface-visibility: hidden; /* 1 */ 
}

/**
 * Pins to all corners by default. But when a width and/or height are
 * provided, the element will be centered in its nearest relatively-positioned
 * ancestor.
 */

.u-posAbsoluteCenter {
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}
