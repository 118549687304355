@use 'sass:math';

/*------------------------------------*\
 #MIXINS/TYPE
\*------------------------------------*/

/**
 * Quickly generate a font-size in rems, with a pixel fallback, based on the
 * value we pass into the mixin, e.g.:
 *
 * h1 {
 *     $include font-size(24px);
 * }
 */
@mixin font-size($font-size, $line-height: true) {
    // scss-lint:disable DuplicateProperty
    font-size: $font-size;
    font-size: math.div($font-size, $font-size-base) * 1rem;
    // scss-lint:enable DuplicateProperty
    

    @if $line-height == true {
        line-height: ceil(math.div($font-size, $base-line-height)) * math.div($base-line-height, $font-size);
    }
}

/**
 * Style any number of headings in one fell swoop, e.g.:
 *
   .foo{
       @include headings(1, 3){
           color:#c0ffee;
       }
    }
 *
 * With thanks to @lar_zzz, @paranoida, @rowanmanning and ultimately
 * @thierrylemoulec for refining and improving my initial mixin.
 */
@mixin headings($from: 1, $to: 6) {
    %base-heading {
        @content
    }

    @if $from >= 1 and $to <= 6 {
        @for $i from $from through $to {
            h#{$i} {
                @extend %base-heading;
            }
        }
    }
}
