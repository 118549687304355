/*------------------------------------*\
    #LIST-BLOCK
\*------------------------------------*/

.listBlock,
.listBlock {
    margin:  0;
    padding: 0;
    list-style: none;

    > li,
    > li > a {
        display: block
    }
}
