/*------------------------------------*\
    #MIXINS/CENTER-BLOCK
\*------------------------------------*/

/**
 * Center-align a block level element
 */
@mixin center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
