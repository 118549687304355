.panel-benefit{
	.panel-body{
		padding:10px;
		h1{
			font-size:1.5em;
			font-weight: 600;
			p{
				font-weight: 600;
			}
		}
	}
}
.panel{
	.panel-heading{
		background-color: #fff !important;
		font-weight: 600;
	}
}
