//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: $line-height-computed 0;
    border-radius: $border-radius-base;
    width: 100%;

    li {
        display: inline; // Remove list-style and block-level defaults
        
        a,
        span {
            position: relative;
            //float: left; // Collapse white-space
            padding: 8px $padding-base-horizontal;
            line-height: $line-height-base;
            text-decoration: none;
            color: $pagination-color;
            background-color: $pagination-bg;
            border: 1px solid $pagination-border;
            margin-left: 4px;
            border-radius: $border-radius-base;
            
            @include transition(all 0.2s ease);
        }
            
        &:first-child {
            a,
            span {
                margin-left: 0;
                
                @include border-left-radius($border-radius-base);
            }
        }
        
        &:last-child {
            a,
            span {
                @include border-right-radius($border-radius-base);
            }
        }
    }

    li a,
    li span {
        &:hover,
        &:focus {
            color: $pagination-hover-color;
            background-color: $pagination-hover-bg;
            border-color: $pagination-hover-border;
        }
    }

    .active a,
    .active span {
        &,
        &:hover,
        &:focus {
            z-index: 2;
            color: $pagination-active-color;
            background-color: $pagination-active-bg;
            border-color: $pagination-active-border;
            cursor: default;
        }
    }

    .disabled {
        span,
        span:hover,
        span:focus,
        a,
        a:hover,
        a:focus {
            color: $pagination-disabled-color;
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border;
            cursor: $cursor-disabled;
        }
    }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  @include pagination-size(
    $padding-base-vertical,
    $padding-base-horizontal,
    $font-size-base,
    $line-height-large,
    $border-radius-base
  );
}

// Small
.pagination-sm {
  @include pagination-size(
    $padding-base-vertical,
    $padding-base-horizontal,
    $font-size-base,
    $line-height-small,
    $border-radius-base
  );
}
