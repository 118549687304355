
.existing-payment-method {
  .credit-card-cvv-container {
    margin: 8px 20px 8px 38px;

    .col-sm-4 {
      padding-right: 0;
      padding-left: 10px;
    }

    .form-control {
      display: inline-block;
      width: auto;
      height: auto;
      line-height: 1.6;
    }

    .credit-card-cvv-label {
      display: initial;
      margin-top: 9px;
      margin-right: 9px;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: normal;
      line-height: 1.2em;
      text-transform: none;
      white-space: nowrap;

      &::after {
        margin-left: 0;
        content: ':';
      }
    }

    .credit-card-cvv-help-block {
      padding-left: 15px;
      margin-bottom: 0;
    }
  }
}

.give-modal-content, .branded-checkout {
  .credit-card-cvv-label:not(.existing-payment-method .credit-card-cvv-label) {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;

    &::after {
      display: inline-block;
      margin-left: 6px;
      content: '*';
      font-family: fontawesome, "Font Awesome 5 Pro", sans-serif;
      font-size: 115%;
      line-height: 0;
    }
  }
}
