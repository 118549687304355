
.form-group.has-error {
  .form-control {
    box-shadow: inset 0 0 0 1px $state-danger-text;
  }

  label > span, label > translate {
    color: $state-danger-text;
    font-weight: 600;

    &::before {
      display: inline-block;
      margin-right: 6px;
      content: "\f071";
      font-family: fontawesome, "Font Awesome 5 Pro", sans-serif;
    }
  }
}

.form-group.has-success {
  .form-control {
    box-shadow: inset 0 0 0 1px $colorHelper-success;
  }

  label > span, label > translate {
    color: $colorHelper-success;

    &::before {
      display: inline-block;
      margin-right: 6px;
      content: "\f00c";
      font-family: fontawesome, "Font Awesome 5 Pro", sans-serif;
    }
  }
}

label:not(:has(input[type="checkbox"])) {
  & > span, & > translate {
    display: block;
    margin-bottom: 4px;
  }
}

.is-required {
  label > span::after, label > translate::after {
    display: inline-block;
    margin-left: 6px;
    content: "*";
    font-family: fontawesome, "Font Awesome 5 Pro", sans-serif;
    font-size: 115%;
    line-height: 0;
  }
}

.form-group {
  label:not(.error) {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
  }
}

.phone-row {
  position: relative;

  select.form-control-subtle {
    display: inline-block !important;
    width: 23%;
    margin-right: -4px;
  }

  input[type="tel"] {
    display: inline-block !important;
    width: 50% !important;
    margin: 0 1% 0 2% !important;
    vertical-align: bottom;
  }

  a {
    position: absolute;
    top: 50%;
    right: -20px;
    color: $colorHelper-wanger;
    transform: translateY(-50%);
    .fa {font-size: 18px;}
  }
}

.people-fields {
  > li {
    .clear {clear: both;}
  }
}

.person-row {
  select, input {margin-left: 3px;}
  select, input, .fa {float: left;}
  .fa {line-height: 39px;}

  select.form-control-subtle {
    display: inline-block !important;
    width: 12%;
    padding-right: 0;
    padding-left: 0;
  }

  input[type="name"] {
    display: inline-block !important;
    width: 50% !important;
    margin: 0 1% 0 2% !important;
    vertical-align: bottom;
  }

  select.prefix {
    @include respond-to(extrasmall) {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 5px;
    }
  }

  input.first {
    width: 30%;

    @include respond-to(extrasmall) {
      width: 73%;
      margin-bottom: 5px;
    }
  }

  input.last {
    width: 30%;

    @include respond-to(extrasmall) {
      width: 60%;
    }
  }

  input.middle {
    width: 8%;

    @include respond-to(extrasmall) {
      width: 12%;
      margin-left: 14px;
    }
  }

  input.first,
  input.last,
  input.middle {
    display: inline-block;
    vertical-align: bottom;
  }
}

.help-block {
  font-size: 85%;
  font-weight: 600;
  line-height: 1.25rem;
}

.btn:focus, .btn:active:focus {
  appearance: none;
  outline: none;
}

form.new-gift {
  .input-group-addon {
    padding: 0;
  }

  .btn-link {
    padding: 8px 22px;
    border-width: 0;
  }
}
