.m-noFloat {
  @include respond-to(supertiny) {
    float: none !important;
  }  
}

.panel-body {
  @include respond-to(extrasmall) {
    padding: $gutter * 0.6666;
  }
}

.container-width {
  max-width: 1170px;
  margin: 0 auto;
}

.container-margin {
  @media screen and (max-width: 1170px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
