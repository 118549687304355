$border-radius: 4px;

#give-hero {
  .hero-image {
    position: relative;
    height: 220px;
    background-size: cover;
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);

    h1 {
      margin-bottom: 0;
      color: $colorCru-gold;
      font-size: 24px;
      font-weight: 400;
    }

    h4 {
      margin-top: 0;
      font-size: 20px;
    }

    h4, p {
      color: $colorCru-white;
    }

    form {
      button.submit-search {
        height: 46px;
        padding: 0 35px;
        border-radius: $border-radius;

        /* Discover more button BG: */
        background: $colorCru-gold;
        font-size: 20px;
        line-height: 43px;
      }

      .form-group {
        margin-right: 15px;

        input {
          width: 300px;
          max-width: 100%;
          height: 48px;
          padding: 0 13px;
          border: none;
          border-radius: $border-radius;
          box-shadow: none;
          font-size: 16px;
          line-height: 20px;
        }

        @media screen and (max-width: 720px) {
          margin-left: 15px;

          input{
            width: 100%;
          }
        }
      }
    }

    .homepage-hero-links {
      display: block;
      padding-top: 0.25em;
      margin-top: 29px;
      clear: both;

      @media screen and (max-width: 720px) {
        margin-top: 1em;
      }

      a {
        display: inline-block;
        padding-right: 0.5em;
        border-right: 1px solid $colorCru-white;
        margin-right: 0.5em;
        color: $colorCru-white;
        font-size: 16px;
        font-weight: 400;

        &:last-child {
          padding-right: 0;
          border-right: none;
          margin-right: 0;
        }
      }
    }
  }

  .hero-leads {
    position: relative;
    margin-top: -30px;

    .hero-lead-first, 
    .hero-lead-second, 
    .hero-lead-third {
      overflow: hidden;
      width: 33.3333%;
      padding: 0;
      margin: 0;
      float: left;


      h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 9px;
        margin-bottom: 9px;
        background-color: rgba(#000, 0.4);
        color: $colorCru-white;
        font-size: 20px;
        font-weight: 600;
      }

      a {
        display: block;
        height: 220px;
        padding-top: 30px;
        text-decoration: none;
        transition: 0.25s all;

        @media screen and (max-width: 860px) {
          height: 185px;
          padding-top: 15px;
        }

        &:hover {
          padding-top: 0;
        }

        .image-container {
          position: relative;
          height: 100%;
          background-color: $input-border;
          background-position: 50%;
          background-size: cover;
          text-align: center;
          transition: 0.25s all;

        }
      }
    }

    .hero-lead-first {
      .image-container {
        background-image: url("/assets/images/people.jpg");
      }
    }

    .hero-lead-second {
      .image-container {
        background-image: url("/assets/images/ministry.jpg");
      }
    }

    .hero-lead-third {
      .image-container {
        background-image: url("/assets/images/featured-opportunities.jpg");
      }
    }

    @media screen and (max-width: 720px) {
      margin-top: 0;
      background-position: 50% 50%;
      background-size: cover;

      .hero-lead-first, 
      .hero-lead-second, 
      .hero-lead-third {
        width: 100%;
        border-bottom: 1px solid #333;

        .image-container {
          padding-top: 9px;
          padding-bottom: 9px;
          background-color: rgba(0,0,0,0.8);
        }

        h3 {
          position: relative;
          margin-top: 0;
          margin-bottom: 0;
        }

        a {
          position: relative;
          height: auto;
          padding-top: 0;
        }
      }
    }

    @media only screen and (min-width: 1400px) {
      margin-top: 0;

      .hero-lead-first, 
      .hero-lead-second, 
      .hero-lead-third {
        a {
          height: auto;
          padding-top: 0;
          transition: none;

          .image-container {
            padding-top: 40.1%;
            transition: none;
          }
        }
      }
    }

  }
}

#give-featured {
  background: $colorCru-white;
}

.give-featured-col {
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
}

.sign-in-slider-col {
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
}

.panel-give-featured {
  max-width: 290px;
  box-shadow: none;

  @media only screen and (max-width: 720px) {
    max-width: none;
  }

  .panel-header {
    padding-left: 20px;
    border: 1px solid $colorCru-mid-gray;
    border-radius: $border-radius $border-radius 0 0;
    background: $colorCru-mid-gray;
    color: $colorCru-white;

    h3 {
      margin: 0 0 0.25em;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .panel-body {
    padding: 20px;
    border: 1px solid $colorGrayscale-pebble;
    border-top: none;

    p {
      line-height: 1.45em;
    }

    a:not(.btn) {
      color: $colorText;
      text-decoration:none;
    }

    @media screen and (max-width: 850px) {
      h3 {
        font-size: 22px;
        font-weight: 500;
      }

      p:nth-last-child(2) {
        display: block;
      }  
    }
  }

  .give-featured-image {
    margin-bottom: $gutter * 0.5;

    img {
      width: 100%;

      @media screen and (max-width: 840px) {
        font-size: 18px;
      }
    }
  }
}

.form-give-home-signin {
  @media screen and (max-width: 991px) {
    .home-self-service {
      margin-top: 8px;

      a.u-block {
        display: inline-block !important;
        margin-left: 16px;
      }
    }
  }

  @media only screen and (max-width: 744px) {
    .form-group:not(:last-child) {margin-right: 10px;}
  }

  @media only screen and (max-width: 720px) {
    display: none;
  }

  .form-group {
    &:not(:last-child) {
      margin-right: $gutter * 0.5;
    }

    > input, button[type="submit"] {
      border-radius: $border-radius;
    }

    &.input-widths {
      width: 200px;

      @media screen and (max-width: 1200px) {
        width: 165px;
      }
    }

    > input[type="text"],
    > input[type="password"],
    > input[type="email"] {
      width: 100%;
    }
  }

  .form-heading {
    pointer-events: none;
    text-transform: uppercase;
  }

  button[type="submit"] {
    width: 150px;
    border-color: $colorCru-gold;
    background-color: $colorCru-white;

    &:hover {
      background-color: $colorCru-gold;
      color: $colorCru-white;
    }

    @media screen and (max-width: 1200px) {
      width: auto;
    }
  }

  .home-self-service {
    line-height: 1.1em;

    a {
      color: $colorText;
    }
  }
}

#give-integrity {
  padding-top: $gutter * 2;
  padding-bottom: $gutter * 2;

  h1, p {
    text-align: center;
  }

  h1 {
    color: $colorCru-gray;
    font-size: 36px;
    font-weight: 600;
  }

  .integrity-opener {
    @media screen and (max-width: 550px) {
      br {
        display: none;
      }
    }
  }

  .integrity-col {
    line-height: 1.35em;

    &::before {
      display: block;
      height: 100px;
      background: no-repeat center center;
      background-size: 70px;
      content: "";
    }

    @media only screen and (max-width: 560px) {
      width: 100%;
    }
  }

  .win::before {
    background-image: url("/assets/images/win.png");
  }

  .build::before {
    background-image: url("/assets/images/build.png");
  }

  .send::before {
    background-image: url("/assets/images/send.png");
  }
}

#give-credibility {
  padding-top: $gutter * 2;
  padding-bottom: $gutter * 2;
  background: $colorCru-white;

  h4 {
    margin-bottom: 0;
    font-weight: 600;
  }

  .credibility-security {
    width: 65%;
  }

  .trust {
    width: 35%;

  }

  .credibility-col {
    float: left;

    p {
      line-height: 1.625;
    }

    h4 {
      margin-bottom: 5px;
    }

    @media screen and (max-width: 550px) {
      text-align: center;
    }

    .credibility-seal {
      width: 15%; 
      margin-right: 30px;
      margin-left: 30px;
      float: right;

      @media screen and (max-width: 550px) {
        display: block;
        width: 100px;
        margin: 0 auto 15px auto;
        float: none;
      }
    }

    .credibility,
    .security {
      margin-bottom: $gutter;

      p {
        width: 75%;

        @media screen and (max-width: 550px) {
          width: auto;
        }
      }

      h4 {
        width: 80%;
        margin-bottom: 5px;

        @media screen and (max-width: 550px) {
          width: auto;
        }
      }
    }

    @media screen and (max-width: 991px) {
      width: 100% !important;
      float: none;
    }
  }  
}
