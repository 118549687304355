.give-home-slider-wrap {
  .cycle-nav {
    position: absolute;
    z-index: 120;
    bottom: 0;
    width: 52%;
    text-align: center;

    @media screen and (max-width: 1200px) {
      bottom: 60px;
      width: 35%;
    }

    @media screen and (max-width: 991px) {
      position: static;
      width: 100%;
      padding-top: 20px;

      &::before,
      &::after {
        display: block;
        width: 100%;
        content: "";
      }
    }

    .prevControl,
    .nextControl {
      display: inline-block;
      overflow: hidden;
      width: 9%;
      height: 16px;
      margin: 0;
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      background: no-repeat 50% 50%;
      background-size: 16px auto;
      cursor: pointer;
      text-indent: -1000px;
      vertical-align: top;
    }

    .prevControl {
      background-image: url("/assets/img/arrow-left-slide.png");
      background-position: middle left;
    }

    .nextControl {
      background-image: url("/assets/img/arrow-right-slide.png");
      background-position: middle-right;
    }

    .cycle-pager {
      display: inline-block;
      width: 60%;
      height: 16px;
      margin: 0;
      cursor: pointer;
      text-align: center;
      vertical-align: top;

      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 100px;
        margin: 2px 3px;
        background: $colorCru-gold;
        color: $colorCru-gold;
        font-size: 16px;
        line-height: 16px;
        vertical-align: top;
      }

      .cycle-pager-active {
        background: $colorCru-blueBright;
        color: $colorCru-blueBright;
      }
    }
  }
}

.give-home-slider.cycle-slideshow {
  position: relative;
  background: none;

  .slide {
    position: relative;
    max-width: 750px;
    height: 400px;

    @media screen and (max-width: 991px) {
      max-width: auto;
      height: auto;
    }

    .slide-image {
      width: 85%;

      @media screen and (max-width: 991px) {
        width: auto;
      }
    }

    .slide-caption {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      width: 45%;
      height: auto;
      padding: $gutter * 1.2;
      background: $colorGrayscale-stone;
      transition: 0.5s all;

      @media screen and (max-width: 1200px) {
        width: 60%;
        padding: $gutter * 0.8;

        .btn {
          padding: 0 10px;
        }
      }

      @media screen and (max-width: 991px) {
        position: static;
        width: auto;
        padding: $gutter;
      }

      h3 {
        margin-left: 0;
        font-size: 24px;
        line-height: 1.35em;
        transition: 0.35s all;
      }
    }

    &:not(.cycle-slide-active) .btn {
      pointer-events: none;
    }
  }
}
