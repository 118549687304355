.btn-radio, .custom-amount{
  min-width: 85px;
  padding: 1% 15px 1% 15px;
  border: 1px solid $colorGrayscale-stone;

  &.btn-wide {
    min-width: 150px;
  }

  input[type="radio"]:checked + label{
    width: 100%;
    background: $colorText--muted;
    color: $colorCru-white;
  }

  input[type="radio"]{
    visibility:hidden;
  }

}

label.custom-amount {
  width: auto;
}



.radio,
.checkbox {
  label {
    min-height: 29px;
    padding-left: 0;
    margin-bottom: 0;
    cursor: pointer;
    font-weight: normal;
  }
}

label.btn-radio {
  .btn span {
    font-size: 18px ;
  }

  input[type="radio"] ~ i.fa.fa-circle-o {
    display: inline; 
    color: $colorGrayscale-pebble;
    font-size: 1em;
    transition-delay: 200ms;
    transition-duration: 200ms;
    transition-property: all;
  }

  input[type="radio"] ~ i.fa.fa-check-circle-o{
    display: none;
  }

  input[type="radio"]:checked ~ i.fa.fa-circle-o{
    display: none;
  }

  input[type="radio"]:checked ~ i.fa.fa-check-circle-o{
    display: inline;
    color: $colorCru-white;
    font-size: 15px;
  }

  &:hover input[type="radio"] ~ i.fa {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: #7aa3cc;
  }

  input[type="checkbox"] ~ i.fa.fa-square-o{
    display: inline;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: #c8c8c8;
  }

  input[type="checkbox"] ~ i.fa.fa-check-square-o{
    display: none;
  }

  input[type="checkbox"]:checked ~ i.fa.fa-square-o{
    display: none;
  }

  input[type="checkbox"]:checked ~ i.fa.fa-check-square-o{
    display: inline;
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: #7aa3cc;
  }

  &:hover input[type="checkbox"] ~ i.fa {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: #7aa3cc;
  }
}

div[data-toggle="buttons"] label.active{
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: #7aa3cc;
}

div[data-toggle="buttons"] label {
  margin-bottom: 10px;
  font-weight: 600;
  transition-delay: 100ms;
  transition-duration: 100ms;
  transition-property: all;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}


@include mq(768, max) {
  div[data-toggle="buttons"] label {
    display: block;
  }
  .give-modal-panel .custom-amount {width: 100% !important;}
  .number {text-align: center;}
}

div[data-toggle="buttons"] label:hover {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  background: #f9f9f9;
  color: $colorText;
}

div[data-toggle="buttons"] label:active,
div[data-toggle="buttons"] label.active {
  border-color: $colorGrayscale-text;
  background-color: $colorGrayscale-text;
  box-shadow: none;
  color: $colorCru-white;
  font-weight: 600;
}

label.btn.btn-default-form.custom-amount.mr.u-textLeft {
  padding-left: 0;
  border: none;
}

label.btn.btn-default-form.active {
  background: none;
}

.modal {
  position: fixed;
  z-index: 1040;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.modal-open {
  .reveal-modal-bg {
    position: fixed;
    z-index: 1004;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background: $colorGrayscale-black;
    background: rgba(0, 0, 0, 0.55);
    transition-delay: 100ms;
    transition-duration: 100ms;
    transition-property: all;
  }

  .modal {
    -webkit-overflow-scrolling: auto; // Hack for Mobile Webkit unable to detect Modal Dynamic Heights.
    overflow-y: scroll !important;  // Hack for Mobile Webkit unable to detect Modal Dynamic Heights.
  }

  .modal-dialog {
    height: 101%; // Hack for Mobile Webkit unable to detect Modal Dynamic Heights.
  }
}

.modal-footer {
  text-align: left;
}

.give-modal-header {
  padding: 30px 40px 15px 40px;
}

.give-gift-header {
  font-size: 40px;
  font-weight: 100;
  line-height: 48px;
}

.give-modal-recipient {
  line-height: 1.55em;
  text-align: right;

  .recipient-image {
    width: 85px;
    float: right;
  }

  .recipient-title, .recipient-id {
    margin-right: 100px;
  }

  .recipient-title {
    display: block;
    font-size: 1.15em;
    font-weight: 600;
  }

  .recipient-id {
    display: block;
  }
}

@media (max-width: 549px) {
  .give-modal-recipient {
    margin-top: 5px;
    .recipient-image {float: left;}

    .recipient-title, .recipient-id {
      margin-right: 0;
      margin-left: 100px;
      text-align: left;
    }
  }
}

.give-modal-panel {
  border: 0;
  margin-bottom: 15px;
  box-shadow: none;

  .panel-heading {
    padding-right: 0;
    padding-left: 0;
    border-width: 0 0 1px 0;
    background: none;
  }

  .panel-body {
    padding: 15px 0 0 0;

    .list-unstyled {
      padding: 0;

      li::before {
        display: none;
        content: "";
        text-indent: 0;
      }

      li {
        text-indent: 0;
      }
    }
  }

  input.number {
    height: auto;
    padding: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    color: $colorCru-white;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .custom-amount {
    width: 125px;
    &:hover, .number:hover {cursor: pointer;}

    ::placeholder {
      color: $colorGrayscale-text;
      transition-delay: 100ms;
      transition-duration: 100ms;
    }

    &.active {
      background-color: $colorGrayscale-text !important;

      ::placeholder {
        color: $colorCru-white;
      }
    }
  }
}

.give-modal-comments {
  display: block;
  display: none;
  width: 100%;
  border-color: $colorGrayscale-stone;
  border-radius: 3px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
}

.gift-offer {
  margin-bottom: 0.85em;
  font-size: 1.15em;
  line-height: 1.15em;

  &.inactive {
    color: lighten($colorGrayscale-text, 40%);

    .fa {
      margin-right: 8px;
      margin-left: 8px;
    }

  }

  &.active {
    .fa-check {
      padding: 4px;
      border: 3px solid $colorCru-gold;
      border-radius: 100%;
    }
  }
}

