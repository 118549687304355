.btn-secondary {
  color: $colorCru-white !important;
  background-color: $colorCru-gray !important;
  border-color: transparent !important;
}

.btn-third {
	color: #007398 !important;
	background-color: #F0FBFF !important;
  	border-color: #007398 !important; 
  	width:49%;
}
.btn-third-inactive {
	color: #D2D2D2 !important;
	background-color: #F3F3F3 !important;
  	border-color: #D2D2D2 !important; 
  	width:49%;
}
