.modal-content.give-modal-content {
  .hide-modal {
    display: none;
  }

  .modal-header {
    padding-top: 30px;
    border-bottom: none;

    h3 {
      margin: 0;
      line-height: 1.25em;
    }

    p {
      margin-bottom: 0;
    }

    button.close {
      position: absolute;
      z-index: 1000;
      top: 10px;
      right: 10px;
      width: 27px;
      height: 27px;
      background: url("/assets/img/close-mobile.png");
      background-size: contain;
      opacity: 1;

      span {
        display: none;
      }
    }

    @media (min-width: 992px) {
      button.close {
        position: static;
        width: 36px;
        height: 36px;
        margin-top: -30px;
        margin-right: -85px;
        background: url("/assets/img/close-icon.png");
        background-size: contain;
        opacity: 1;

        span {
          display: none;
        }
      }
    }

    .border-bottom-small {
      margin-bottom: 0;
    }
  }

  .modal-body {
    .repeating-row {
      @include clearfix;

      &::after {
        display: block;
        padding-bottom: $gutter * 0.5;
        border-bottom: 1px solid lighten($colorGrayscale-text, 60%);
        margin-right: 15px;
        margin-bottom: $gutter * 0.5;
        margin-left: 15px;
      }

      &:last-child::after {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  .alert {
    margin-bottom: $gutter * 0.5;
  }
}

$row-eq-height-breakpoints: 549;

@each $breakpoint in $row-eq-height-breakpoints {
  @media screen and (max-width: #{$breakpoint}px) {
    .row-eq-height.break-flex-at-#{$breakpoint} {
      display: block !important;
    }
  }
}

.modal-sm {
  .give-modal-content {
    .modal-body {
      padding-top: 0;
    }
  }
}

body{
  @media (min-width: 1024px) {
    .modal-large {
      width: auto;
      max-width: 800px;
      margin: 30px auto;

      $modpad: $gutter * 1;

      .modal-body {
        padding: $modpad $modpad 0 $modpad;
      }

      .modal-footer {
        padding-right: $modpad;
        padding-left: $modpad;
      }
    }
  }
}

.modal-content {
  .form-group {
    label.radio {
      padding-bottom: 10px;
      padding-left: 23px;
      text-transform: none;
    }
  }

  p {
    line-height: 1.425em;
  }
}

@media (min-width: 550px) {
  .modal-sm {
    width: 450px;
  }
}

@media (max-width: 440px) {
  #saveChangesButton, #cancelButton, #backButton, #continueButton {
    display: block;
    width: 100%;
  }

  #saveChangesButton, #continueButton {
    margin-bottom: 10px;
    float: none !important;
  }
}

#checkoutModal1, #checkoutModal2, #checkoutModal3 {
  .mb_x {
    margin-bottom: 29px !important;
  }
}

#checkoutModal2 {
  .bank-name, .account-type {
    width: 100% !important;
  }
}

#addNewPaymentModal{
  background-color: rgba(138, 138, 138, 0.9); 

  .light-font{
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: #d3d3d3;
    font-size: 14px;
    font-weight: bold;
  }
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $colorGrayscale-black;
}
